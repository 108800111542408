<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Actions -->
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <div class="col col-lg-auto">
            <b-button
              variant="primary"
              :to="{ name: 'apps-transactions-beginning-balance-add'}"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('globalActions.create') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: Year -->
      <template #cell(year)="{ item }">
        {{ item.year }}
      </template>

      <!-- Column: Month -->
      <template #cell(month)="{ item }">
        {{ getMonthName(item.month) }}
      </template>

      <!-- Column: Account -->
      <template #cell(account)="{ item }">
        <b-link
          :to="{ name: 'apps-transactions-beginning-balance-detail', params: { id: item.id }}"
          class="font-weight-bold"
        >
          {{ item.account ? item.account.label : 'Unknown' }}
        </b-link>
      </template>

      <!-- Column: Amount -->
      <template #cell(open_amount)="{ item }">
        {{ formatCurrency(item.open_amount) }}
      </template>

      <!-- Column: Office -->
      <template #cell(office_id)="{ item }">
        {{ item.office ? item.office.label : 'Unknown' }}
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        By {{ item.getUser ? item.getUser.user_name : 'Unknown' }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >{{ $t("globalSingular.showing") }} {{ dataMeta.from }}
            {{ $t("globalSingular.to") }} {{ dataMeta.to }}
            {{ $t("globalSingular.of") }} {{ dataMeta.of }}
            {{ $t("globalSingular.entries") }}</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            per-page="15"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import { getYears, getMonthName, getMonths } from '@/utils'
import useListTablePage from '@/comp-functions/useListTablePage'
import { ref } from '@vue/composition-api'
import useLocalization from '@/comp-functions/utils/useLocalization'
import TableSearchColumn from "@/components/TableSearchColumn.vue"


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    TableSearchColumn
  },

  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData
    } = useListTablePage({
      url: 'master/bl-account',
    })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'account',
        label: 'Account',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '', key: 'account' }
      },
      {
        key: 'year',
        label: 'Year',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          key: 'year',
          value: '',
          options: getYears()
        }
      },
      {
        key: 'month',
        label: 'Month',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'month',
          options: getMonths().map(month => ({
            label: month.label,
            id: month.value
          }))
        }
      },
      {
        key: 'open_amount',
        label: 'Open Amount',
        thClass: 'text-right px-1',
        tdClass: 'text-right px-1',
        search: { type: 'number', value: 0, key: 'open_amount' }
      },
      {
        key: 'office_id',
        label: 'Office',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '', key: 'office' }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '', key: 'audit' }
      }
    ])

    return {
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchData,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      changePerPage,
      loadPage,
      focusedSearchField,
      formatCurrency,
      unformatNumber,
      getMonthName,
    }
  },
}
</script>
